<template>
  <div class="clothing-data-form">
    <div class="form-nav">
      <div class="d-flex justify-content-between align-items-center">
        <div class="info-group">
          <h2 class="fs-h2">
            <span>[</span>
            <span v-text="formattedEventType"></span>
            <span>] </span>
            <span v-text="formData.event.displayName"></span>
          </h2>
          <div class="desc fs-14px" v-text="formData.businessAccount.name"></div>
          <b-button class="fs-16px fw-bold" variant="link" @click="checkCollectionStandard">查看收件標準</b-button>
        </div>
<!--        <b-button class="collect-more fs-16px" variant="outline-primary">選取更多單品</b-button>-->
        <b-dropdown
            v-if="false"
            text="選取更多單品"
            block
            variant="outline-primary"
            class="collect-more fs-16px"
            menu-class="w-100"
            no-caret
        >
          <b-dropdown-item-button @click="openEditCollectionItemModal('上傳單品')">上傳單品</b-dropdown-item-button>
          <b-dropdown-item-button  @click="openCollectFromClosetModal">從衣櫥中選取</b-dropdown-item-button>
        </b-dropdown>
      </div>
      <div class="notice-list">
        <div class="notice-item" v-if="filteredCloths.unfilled.length !== 0">
          <div class="name">
            <div class="fs-12px fw-bold">衣物資訊不完整</div>
          </div>
          <div class="desc fs-16px">
            <span v-text="filteredCloths.unfilled.length"></span>
            <span>件</span>
          </div>
        </div>
        <div class="notice-item cursor-pointer" @click="toggleDatelineNotice">
          <div class="name">
            <div class="fs-12px fw-bold">送出衣物期限</div>
            <Icon name="Info_fill" size="16" />
          </div>
          <div class="desc fs-16px">收滿截止</div>
        </div>
        <CustomAlertModal :msg="datelineNoticeMsg" :btn-txt="'返回'" v-model="datelineNotice" />
        <div class="notice-item">
          <div class="name">
            <div class="fs-12px fw-bold">可寄售上限</div>
          </div>
          <div class="desc fs-16px">
            <span v-text="formData.clothAmountLimit"></span>
            <span>件</span>
          </div>
        </div>
      </div>
      <b-dropdown
          v-if="formDetailData"
          :text="formDetailData.clothAmount > 0?'選取更多單品':'選取寄售單品'"
          block
          :variant="formDetailData.clothAmount > 0?'outline-primary':'primary'"
          class="collect-first"
          menu-class="w-100"
          no-caret
      >
        <b-dropdown-item-button @click="openEditCollectionItemModal('上傳單品')">上傳單品</b-dropdown-item-button>
        <b-dropdown-item-button  @click="openCollectFromClosetModal">從衣櫥中選取</b-dropdown-item-button>
      </b-dropdown>
    </div>

    <div class="form-content">
      <div class="content-block" v-if="filteredCloths.unfilled.length === 0 && filteredCloths.filled.length === 0">
        <div class="block-nav">
          <h3 class="fs-h3">單品資訊</h3>
          <div class="desc fs-14px">目前尚未有單品資訊</div>
        </div>
        <div class="none-item">
          <img :src="imageUrl.noneItemImage" alt="none item image">
        </div>
      </div>

      <div class="content-block" v-if="filteredCloths.unfilled.length !== 0">
        <div class="block-nav">
          <h3 class="fs-h3">
            <span>待完成：</span>
            <span v-text="filteredCloths.unfilled.length"></span>
            <span> 件</span>
          </h3>
          <div class="d-flex align-items-center">
            <Icon class="warning" name="Info_fill" size="16" />
            <div class="desc fs-14px">欲送出衣物資料，請填寫完整資料或刪除單品</div>
          </div>
        </div>
        <div class="single-item-clothes-list">
          <SingleItemClothesCard v-for="(item, index) in filteredCloths.unfilled" :key="index" :card-data="item" @edit="editItem(item.serial)" edit-text="填寫完整資訊" @remove="removeItem(item.serial)" />
        </div>
      </div>

      <div class="content-block" v-if="filteredCloths.filled.length !== 0">
        <div class="block-nav">
          <h3 class="fs-h3">
            <span>已完成：</span>
            <span v-text="filteredCloths.filled.length"></span>
            <span> 件</span>
          </h3>
        </div>
        <div class="single-item-clothes-list">
          <SingleItemClothesCard v-for="(item, index) in filteredCloths.filled" :key="index" :card-data="item" @edit="editItem(item.serial)" @remove="removeItem(item.serial)" />
        </div>
      </div>

    </div>

    <CustomModalFooter class="form-footer d-flex justify-content-between align-items-center flex-nowrap">
      <div class="info">
        <h3 class="fs-h3" v-if="formDetailData">
          <span>已選取 </span>
          <span v-text="formDetailData.clothAmount"></span>
          <span>/</span>
          <span v-text="formDetailData.clothAmountLimit"></span>
          <span> 件</span>
        </h3>
        <div class="desc fs-14px">收滿截止</div>
      </div>
      <b-button variant="primary" :disabled="showFinishedBtn" @click="openSubmitClothingDataModal">
        <span>送出衣物資料</span>
      </b-button>
    </CustomModalFooter>

    <transition name="fade">
      <FullScreenModal v-if="showCollectionStandard" title="填寫衣物資料" @back="closeCollectionStandardModal">
        <ClothingCollectionStandardForm :formData="formData" />
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <FullScreenModal v-if="editCollectionItem.showEditCollectionItem" :title="editCollectionItem.modalTitle" @back="closeEditCollectionItemModal">
        <EditCollectionItem :itemSerial="editCollectionItem.itemSerial" :itemData="editCollectionItem.itemData" @updateEditedData="updateEditedData" />
      </FullScreenModal>
    </transition>

    <CollectFromCloset v-if="formDetailData && showCollectFromCloset" :collectLimit="formDetailData.clothAmountLimit" :collectedList="[]" v-model="showCollectFromCloset" @uploadCollectedItem="updateSelectedList" />

    <SubmitClothingData v-if="formDetailData" :businessAccount="formData.businessAccount" :formData="formDetailData" v-model="showSubmitClothingData" @submitSuccess="submitSuccess"></SubmitClothingData>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import CustomAlertModal from "@/components/commons/CustomAlertModal.vue";
import SingleItemClothesCard from "@/views/share/SingleItemClothesCard.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import FullScreenModal from "@/components/FullScreenModal.vue";
import ClothingCollectionStandardForm from "@/views/share/ClothingCollectionStandardForm.vue";
import EditCollectionItem from "@/views/share/EditCollectionItem.vue";
import CollectFromCloset from "@/components/CollectFromCloset.vue";
import SubmitClothingData from "@/views/share/SubmitClothingData.vue";

import NoneItemImage from "@/assets/images/svg/Puzzle-01.svg";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ClothingDataForm',
  data() {
    return {
      readingBusyName: 'ClothingDataForm',

      init: false,

      datelineNotice: false,
      datelineNoticeMsg: '此訂單僅為通知服務商您欲寄售服裝，如已累積足夠寄售件數，服務商有權隨時停止收件，請盡快送出本表單。如已截止收件，我們將會以email通知您。',
      formDetailData: null,
      selectedList: [],

      showCollectionStandard: false,
      editCollectionItem: {
        modalTitle: '',
        itemSerial: -1,
        itemData: null,
        showEditCollectionItem: false,
      },
      showCollectFromCloset: false,
      showSubmitClothingData: false,

      imageUrl: {
        noneItemImage: NoneItemImage,
      }
    };
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomAlertModal,
    SingleItemClothesCard,
    FullScreenModal,
    CustomModalFooter,
    ClothingCollectionStandardForm,
    EditCollectionItem,
    CollectFromCloset,
    SubmitClothingData,
  },
  computed: {
    formattedEventType() {
      switch (this.formData.event.type) {
        case 0:
          return '寄售';
        case 1:
          return '捐贈';
        default:
          return '錯誤狀態'
      }
    },
    filteredCloths() {
      if(this.formDetailData) {
        const cloths = [...this.formDetailData.cloths];

        const unfilledCloths = cloths.filter(cloth => {
          return (
              cloth.imgs.length === 0 ||
              cloth.category1 === -1 ||
              cloth.category2 === -1 ||
              cloth.brand === '' ||
              cloth.size === '' ||
              cloth.boughtPrice < 0 ||
              cloth.erosionRate.length === 0
          );
        });

        return {
          unfilled: unfilledCloths,
          filled: cloths.filter(cloth => !unfilledCloths.includes(cloth)),
        };
      } else {
        return {
          unfilled: [],
          filled: [],
        };
      }
    },
    filteredCollectedClothSerials() {
      return this.formDetailData ? this.formDetailData.cloths.filter(cloth => cloth.originClothSerial !== -1).map(cloth => cloth.originClothSerial) : [];
    },
    showFinishedBtn() {
      return this.formDetailData?.cloths?.length === 0 || this.filteredCloths.filled.length !== this.formDetailData?.cloths?.length;
    },
  },
  watch: {
  },
  async mounted() {
    await this.handleAsyncTask(this.readInfo);
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData']),

    async readInfo() {
      this.formDetailData = await this.$store.dispatch('api/getReviewOrderDetailPromise', this.formData.serial);
    },
    toggleDatelineNotice() {
      this.datelineNotice = !this.datelineNotice;
    },
    checkCollectionStandard() {
      this.showCollectionStandard = true;
    },
    closeCollectionStandardModal() {
      this.showCollectionStandard = false;
    },
    async openEditCollectionItemModal(title) {
      this.editCollectionItem.modalTitle = title;
      if(title === '上傳單品') {
        await this.handleAsyncTask(async () => {
          const orderSerial = this.formDetailData.serial;
          const res = await this.$store.dispatch('api/postReviewClothCreatePromise', { orderSerial: orderSerial });
          console.log(res)
          this.editCollectionItem.itemSerial = res.serial;
          await this.readInfo();
        });
      }
      this.editCollectionItem.itemData = await this.formDetailData.cloths.find(item => item.serial === this.editCollectionItem.itemSerial);
      this.editCollectionItem.showEditCollectionItem = true;
    },
    editItem(serial) {
      this.editCollectionItem.itemSerial = serial;
      this.openEditCollectionItemModal('編輯單品');
    },
    async removeItem(serial) {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postReviewClothRemovePromise', { reviewClothSerial: serial });
        await this.readInfo();
      });
    },
    closeEditCollectionItemModal() {
      this.editCollectionItem.showEditCollectionItem = false;
    },
    async updateEditedData(data) {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postReviewClothUpdatePromise', data);
        await this.readInfo();
      });
      this.editCollectionItem.showEditCollectionItem = false;
    },
    openCollectFromClosetModal() {
      this.showCollectFromCloset = true;
    },
    async selectedFromCloset(clothSerials) {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postReviewClothCreateFromCloset', { orderSerial: this.formDetailData.serial, clothSerials: clothSerials });
      });
    },
    async updateSelectedList(clothSerials) {
      // console.log(data);
      await this.selectedFromCloset(clothSerials);
      // data.forEach((serial) => this.selectedFromCloset(serial));
      await this.handleAsyncTask(async () => {
        await this.readInfo();
      });
    },
    openSubmitClothingDataModal() {
      this.showSubmitClothingData = true;
    },
    async submitSuccess() {
      await this.refreshHistoryList();
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.clothing-data-form {
  display: flex;
  flex-direction: column;
  @include min-height-with-modal-nav;
  .form-nav {
    border-bottom: .5rem solid $color-border;
    padding: 1rem;
  }
  .info-group {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    h2.fs-h2 {
      margin-bottom: 0;
    }
    .btn.btn-link {
      display: flex;
      padding: 0;
    }
  }
  .notice-list {
    position: relative;
    display: flex;
    align-items: center;
    gap: .5rem;
    min-width: calc(100% + 1rem);
    overflow-x: auto;
    margin-top: 1rem;
  }
  .notice-item {
    flex: 0 0 auto;
    background: $color-notice;
    border-radius: .25rem;
    padding: .5rem 1rem;
    .name {
      display: flex;
      align-items: center;
      gap: .25rem;
      margin-bottom: .25rem;
      svg {
        color: $typography-text-link;
      }
    }
  }
  .collect-first {
    margin-top: 1.5rem;
  }
  .form-content {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    padding: .5rem 0 5rem;
  }
  .content-block {
    padding-top: 1rem;
    &:not(:last-child) {
      border-bottom: .25rem solid $color-border;;
    }
    .none-item {
      display: flex;
      justify-content: center;
      padding: 3rem 0;
    }
  }
  .block-nav {
    padding: 0 1rem;
    h3.fs-h3 {
      margin-bottom: .5rem;
    }
    svg.warning {
      margin-right: .25rem;
    }
  }
  .form-footer {
    gap: 1rem;
    h3.fs-h3 {
      margin-bottom: 0;
    }
  }
  .desc {
    color: $typography-secondary-default;
  }
  .warning {
    color: $typography-primary-error;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.clothing-data-form {
  .clothing-collection-standard-form {
    padding: 2rem 1rem 83px;
  }
  .collect-more {
    width: 9rem;
  }
  .collect-first {
    .dropdown-item {
      text-align: center;
    }
  }
  .single-item-clothes-list {
    .single-item-clothes-card:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
  }
}
</style>
  