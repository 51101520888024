<template>
  <div class="market-place-history">
    <div class="history-list">
      <HistoryItem v-for="(item, index) in sortedHistoryListData" :key="index" :item-data="item" @delete="deleteOrder" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import HistoryItem from "@/views/share/HistoryItem.vue";

export default {
  name: 'MarketPlaceHistory',
  data() {
    return {
      readingBusyName: 'MarketPlaceHistory',
      savingBusyName: 'MarketPlaceHistorySave',
      init: false,
    };
  },
  components: {
    HistoryItem,
  },
  computed: {
    ...mapState('event', ['historyListData']),

    sortedHistoryListData() {
      // return this.historyListData
      //     ? [...this.historyListData]
      //         // .filter(item => item.status > 1)  // 過濾掉 status <= 1 的資料
      //         .sort((a, b) => {
      //           return new Date(b.createdDate) - new Date(a.createdDate);
      //         })
      //     : null;
      if (!this.historyListData) return null;

      // 先過濾出 status 为 0, 1, 4, 5 的數據並按時間排序
      const prioritized = [...this.historyListData]
          .filter(item => [0, 1, 4, 5].includes(item.status))
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      // 剩下的其他數據按時間排序
      const others = [...this.historyListData]
          .filter(item => ![0, 1, 4, 5].includes(item.status))
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      // 把 prioritized 列表放在前面，others 列表放在后面
      return [...prioritized, ...others];
    }
  },
  watch: {
  },
  async mounted() {
    await this.refreshHistoryList();
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData', 'removeFromHistoryListData']),
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async deleteOrder(order) {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const deletedOrder = await this.$store.dispatch('api/postReviewOrderCancelPromise', order.serial);
        this.removeFromHistoryListData(deletedOrder.serial);
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.market-place-history {
  padding: 0 .5rem;
  .history-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.market-place-history {
}
</style>
  