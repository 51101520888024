<template>
  <ValidationObserver ref="observer" class="edit-collection-item" tag="div">
    <ValidationProvider ref="imgValidation" tag="div" :rules="formattedImgRules" v-slot="{ errors }" class="form-group-img">
      <div class="images-group">
        <div class="collection-image" v-for="(url, index) in editData.imgs" :key="index">
          <img :src="url" alt="clothing image">
          <a href="#" @click.prevent="removeImg(index)" class="h2 p-2">
            <b-icon icon="x" class="text-dark"></b-icon>
          </a>
        </div>
        <!-- <ImageUploadButton v-show="editData.imgs.length < 4" :upload-function="setImageData">
          <div class="collection-image upload-btn d-flex flex-column justify-content-center align-items-center" :class="{'first': editData.imgs.length === 0}">
            <Icon v-if="editData.imgs.length === 0" name="clothing" size="40" />
            <Icon v-if="editData.imgs.length !== 0" name="Camera" size="24" />
            <div class="fs-12px fw-bold" v-text="uploadBtnText"></div>
          </div>
        </ImageUploadButton> -->
        <CropImageButton @uploaded="clothPictureUploaded" v-show="editData.imgs.length < 4">
          <div class="collection-image upload-btn d-flex flex-column justify-content-center align-items-center" :class="{'first': editData.imgs.length === 0}">
            <Icon v-if="editData.imgs.length === 0" name="clothing" size="40" />
            <Icon v-if="editData.imgs.length !== 0" name="Camera" size="24" />
            <div class="fs-12px fw-bold" v-text="uploadBtnText"></div>
          </div>
        </CropImageButton>
      </div>
      <div class="error-message warning" v-if="errors.length > 0">{{ errors[0] }}</div>
    </ValidationProvider>
    <div class="h3 fs-h3">單品資訊</div>
    <ValidationProvider ref="typesValidation" tag="div" :rules="formattedTypesRules" v-slot="{ errors }">
      <b-form-group class="form-group-types">
        <template v-slot:label>
          <h4 class="fs-h4">
            <span>類別:</span><span class="warning">*</span>
          </h4>
        </template>
        <div class="group-content">
          <b-form-select
              v-model="editData.category1"
              :options="category1Options"
              required
          >
          </b-form-select>
          <b-form-select
              v-model="editData.category2"
              :options="category2Options"
              required
          ></b-form-select>
        </div>
        <div class="error-message warning" v-if="errors.length > 0">{{ errors[0] }}</div>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider ref="brandValidation" tag="div" rules="required" v-slot="{ errors }">
      <b-form-group class="form-group-brand">
        <template v-slot:label>
          <h4 class="fs-h4">
            <span>品牌:</span><span class="warning">*</span>
          </h4>
        </template>
        <b-form-input id="input-brand-name" v-model="editData.brand" trim placeholder="輸入品牌名稱"></b-form-input>
        <div class="error-message warning" v-if="errors.length > 0">{{ errors[0] }}</div>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider ref="sizeValidation" tag="div" rules="required" v-slot="{ errors }">
      <b-form-group class="form-group-size">
        <template v-slot:label>
          <h4 class="fs-h4">
            <span>尺寸:</span><span class="warning">*</span>
          </h4>
        </template>
        <ClothSizeSelectNewStyle v-model="editData.size" />
        <div class="error-message warning" v-if="errors.length > 0">{{ errors[0] }}</div>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider ref="priceValidation" tag="div" rules="boughtPriceRequired" v-slot="{ errors }">
      <b-form-group class="form-group-price">
        <template v-slot:label>
          <h4 class="fs-h4">
            <span>購入金額:</span><span class="warning">*</span>
          </h4>
        </template>
        <b-form-input id="input-brand-price" v-model="editData.boughtPrice" placeholder="NTD$"></b-form-input>
        <div class="error-message warning" v-if="errors.length > 0">{{ errors[0] }}</div>
      </b-form-group>
    </ValidationProvider>
    <b-form-group class="form-group-condition">
      <template v-slot:label>
        <h4 class="fs-h4">
          <span>新舊程度:</span><span class="warning">*</span>
        </h4>
        <div class="fs-14px">
          如有破損請拍照上傳破損處細節
        </div>
      </template>
      <ValidationProvider ref="erosionRateValidation" tag="div" rules="required" v-slot="{ errors }" class="select-group">
        <b-form-select
            v-model="editData.erosionRate"
            :options="condition"
            required
        >
        </b-form-select>
        <div class="error-message warning" v-if="errors.length > 0">{{ errors[0] }}</div>
      </ValidationProvider>
      <div class="defective-checkbox-group d-flex">
        <b-form-checkbox v-model="editData.isFlaw">有損壞</b-form-checkbox>
        <b-form-checkbox v-model="editData.hasDirt">有污漬</b-form-checkbox>
        <b-form-checkbox v-model="editData.isFaded">有褪色</b-form-checkbox>
      </div>
      <div class="form-group-img defective" v-if="editData.isFlaw || editData.hasDirt || editData.isFaded">
        <div class="images-group">
          <div class="collection-image" v-for="(url, index) in editData.flawImgs" :key="index">
            <img :src="url" alt="clothing image">
            <a href="#" @click.prevent="removeDefectiveImg(index)" class="h2 p-2">
              <b-icon icon="x" class="text-dark"></b-icon>
            </a>
          </div>
          <CropImageButton @uploaded="setDefectiveImageData" v-show="editData.flawImgs.length < 4">
            <div class="collection-image defective upload-btn d-flex flex-column justify-content-center align-items-center">
              <Icon name="cloud-upload" size="40" />
              <div class="fs-12px fw-bold">破損照片</div>
            </div>
          </CropImageButton>
          <!-- <ImageUploadButton v-show="editData.flawImgs.length < 4" :upload-function="setDefectiveImageData">
            <div class="collection-image defective upload-btn d-flex flex-column justify-content-center align-items-center">
              <Icon name="cloud-upload" size="40" />
              <div class="fs-12px fw-bold">破損照片</div>
            </div>
          </ImageUploadButton> -->
        </div>
      </div>
    </b-form-group>
    <ValidationProvider ref="psValidation" tag="div" :rules="formattedAccessoryMaterialRules" v-slot="{ errors }">
      <b-form-group class="form-group-note">
        <template v-slot:label>
          <h4 class="fs-h4">
            <span>其他:</span><span class="warning" v-if="isAccessoryType">*</span>
          </h4>
        </template>
        <b-form-textarea
            id="textarea"
            v-model="editData.ps"
            :placeholder="(isAccessoryType)?`請說明材質及飾品尺寸（如：戒圍、項鍊長度等）`:`請說明單品材質、單品相關資訊及其他備註`"
            rows="3"
            max-rows="6"
            
        ></b-form-textarea>
      </b-form-group>
      <div class="error-message warning" v-if="errors.length > 0">{{ errors[0] }}</div>
    </ValidationProvider>
    <CustomModalFooter>
      <b-button variant="primary" block @click="checkError">
        <span>上傳</span>
      </b-button>
    </CustomModalFooter>
  </ValidationObserver>
</template>

<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import {mapActions, mapState} from 'vuex';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
// import ImageUploadButton from "@/components/commons/ImageUploadButton.vue";
import ClothSizeSelectNewStyle from "@/components/ClothSizeSelectNewStyle.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import CropImageButton from '@/components/cropImage/CropImageButton.vue';

extend('required', {
  ...required,
  message: '此為必填欄位。',
});
extend('imgRequired', {
  ...required,
  message: '請上傳至少一張單品照片。'
});
extend('typesRequired', {
  validate() {
    return false;
  },
  message: '請選擇單品類別。'
});
extend('boughtPriceRequired', {
  validate(value) {
    return value >= 0;
  },
  message: '金額不可為負值。'
});
extend('psRequired', {
  validate() {
    return false;
  },
  message: '請選擇輸入飾品材質'
});

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'EditCollectionItem',
  data() {
    return {
      readingBusyName: 'EditCollectionItem',

      init: false,
      editData: {
        serial: this.itemData.serial,
        imgs: this.itemData.imgs,
        category1: this.itemData.category1,
        category2: this.itemData.category2,
        brand: this.itemData.brand,
        size: this.itemData.size,
        boughtPrice: this.itemData.boughtPrice,
        erosionRate: this.itemData.erosionRate,
        isFlaw: this.itemData.isFlaw,
        hasDirt: this.itemData.hasDirt,
        isFaded: this.itemData.isFaded,
        flawImgs: this.itemData.flawImgs,
        ps: this.itemData.ps,
      },
      condition: [{ text: '選擇幾成新', value: '' }, '全新', '90% 新', '80% 新', '70% 新', '60% 新', '50% 新'],
    };
  },
  props: {
    itemSerial: {
      type: Number,
      required: true,
    },
    itemData: {
      type: Object,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    // ImageUploadButton,
    ClothSizeSelectNewStyle,
    CustomModalFooter,
    CropImageButton,
  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),

    formattedImgRules() {
      return this.editData.imgs.length === 0 ? 'imgRequired' : '';
    },
    formattedTypesRules() {
      return this.editData.category1 === -1 || this.editData.category2 === -1 ? 'typesRequired' : '';
    },
    isAccessoryType() {
      return (this.editData.category1 === 15 || this.editData.category1 === 13 || this.editData.category1 === 16);
    },
    formattedAccessoryMaterialRules() {
      return (this.isAccessoryType) ? 'required' : '';
    },
    uploadBtnText() {
      if(this.editData.imgs.length === 0) {
        return '封面照（必填）';
      } else {
        return '其他照片';
      }
    },
    category1Options() {
      const opts = [{
        text: '單品大類',
        value: -1,
      }];
      this.clothCategories.map((item) => {
        opts.push({
          text: item.name,
          value: item.serial
        });
      });
      return opts;
    },
    category2Options() {
      const opts = [{
        text: '單品小類',
        value: -1,
      }];
      const selectedC1 = this.clothCategories.filter((item) => {
        return item.serial === this.editData.category1;
      });
      if (selectedC1.length > 0) {
        selectedC1[0].sub.map((item) => {
          opts.push({
            text: item.name,
            value: item.serial
          });
        });
      }
      return opts;
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),

    previewImage(file) {
      return URL.createObjectURL(file);
    },
    async setImageData(file) {
      if(this.editData.imgs.length < 4) {
        await this.handleAsyncTask(async () => {
          const data = await this.$store.dispatch('api/postReviewClothUploadImagePromise', file);
          console.log(data)
          this.editData.imgs.push(data.url);
        });
        // return Promise.resolve('Uploaded an image');
      } else {
        // return Promise.reject('Maximum number of images reached');
      }
    },
    clothPictureUploaded(data) {
      if(this.editData.imgs.length < 4) {
        this.editData.imgs.push(data.url);
      }
    },
    removeImg(index) {
      this.editData.imgs.splice(index, 1);
    },
    // clothImgUploaded(data) {
    //   const {url} = data;
    //   if (this.cloth.imgs.length > 0) {
    //     this.cloth.imgs.splice(0, 1, url);
    //   } else {
    //     this.cloth.imgs.push(url);
    //   }
    // },
    // async setDefectiveImageData(file) {
    //   if(this.editData.flawImgs.length < 4) {
    //     await this.handleAsyncTask(async () => {
    //       const data = await this.$store.dispatch('api/postReviewClothUploadImagePromise', file);
    //       this.editData.flawImgs.push(data.url);
    //     });
    //     return Promise.resolve('Uploaded an image');
    //   } else {
    //     return Promise.reject('Maximum number of images reached');
    //   }
    // },
    setDefectiveImageData(data) {
      if(this.editData.flawImgs.length < 4) {
        this.editData.flawImgs.push(data.url);
      }
    },
    removeDefectiveImg(index) {
      this.editData.flawImgs.splice(index, 1);
    },
    checkError() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          console.log('Validation successful');
          this.$emit('updateEditedData', this.editData);
        } else {
          console.log('Validation failed');
          this.scrollToError();
        }
      });
    },
    scrollToError() {
      for (const refName in this.$refs) {
        const validationProvider = this.$refs[refName];
        if (validationProvider.errors?.length > 0) {
          validationProvider.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          break;
        }
      }
    },
    updateEditedData() {
      this.$emit('updateInfo', this.editData);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.edit-collection-item {
  padding: 1.5rem 1rem 6.5rem;
  .error-message {
    font-size: $font-extra-small;
    margin-top: .25rem;
  }
  .form-group-img {
    margin-bottom: 1.5rem;
    &.defective {
      margin-top: 1rem;
      margin-bottom: 0;
    }
    .images-group {
      position: relative;
      display: flex;
      align-items: center;
      gap: .5rem;
      width: calc(100% + 1rem);
      overflow-x: auto;
      padding-right: 1rem;
    }
  }
  .collection-image {
    flex: 0 0 auto;
    width: 109px;
    height: 109px;
    border-radius: .5rem;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    a {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .form-group {
    h4.fs-h4 {
      margin-bottom: 0;
    }
  }
  .form-group-condition {
    h4.fs-h4 {
      margin-bottom: .5rem;
    }
    .select-group {
      margin-bottom: 1rem;
    }
  }
  .defective-checkbox-group {
    gap: 1rem;
  }
  //.select-size {
  //  flex: 0 0 27%;
  //}
  .upload-btn {
    color: $typography-secondary-default;
    border: 1px dashed $color-border;
    &.first {
      background: $color-yellow-light;
    }
    &.defective {
      background: $color-gray-light;
    }
  }
  .warning {
    color: $typography-primary-error;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.edit-collection-item {
  .form-group {
    margin-bottom: 1.5rem;
  }
  .img-upload {
    width: auto;
  }
  .upload-btn .svg-icon {
    margin-bottom: .5rem;
  }
  .form-group-types {
    .group-content {
      display: flex;
      gap: .5rem;
    }
  }
}
</style>
  