import { render, staticRenderFns } from "./EditCollectionItem.vue?vue&type=template&id=622908e6&scoped=true"
import script from "./EditCollectionItem.vue?vue&type=script&lang=js"
export * from "./EditCollectionItem.vue?vue&type=script&lang=js"
import style0 from "./EditCollectionItem.vue?vue&type=style&index=0&id=622908e6&prod&lang=scss&scoped=true"
import style1 from "./EditCollectionItem.vue?vue&type=style&index=1&id=622908e6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622908e6",
  null
  
)

export default component.exports