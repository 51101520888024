<template>
  <div class="order-price-check-tabs">
    <Flickity ref="tabsFlickity" :options="tabsFlickityOpt" class="tabs-flickity">
      <div class="category-tab fs-16px" :class="{'selected': selectingCategory === 'pending'}" @click="tabClicked('pending')">
        <span>待確認 (</span>
        <span v-text="tabLength[0]"></span>
        <span>)</span>
      </div>
      <div class="category-tab fs-16px" :class="{'selected': selectingCategory === 'confirmed'}" @click="tabClicked('confirmed')">
        <span>同意 (</span>
        <span v-text="tabLength[1]"></span>
        <span>)</span>
      </div>
      <div class="category-tab fs-16px" :class="{'selected': selectingCategory === 'rejected'}" @click="tabClicked('rejected')">
        <span>不同意 (</span>
        <span v-text="tabLength[2]"></span>
        <span>)</span>
      </div>
      <div class="category-tab fs-16px" :class="{'selected': selectingCategory === 'failed'}" @click="tabClicked('failed')">
        <span>店家拒絕 (</span>
        <span v-text="tabLength[3]"></span>
        <span>)</span>
      </div>
    </Flickity>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';

export default {
  name: 'OrderPriceCheckTabs',
  data() {
    return {
      selectingCategory: 'pending',
    }
  },
  components: {
    Flickity,
  },
  computed: {
    tabsFlickityOpt() {
      return {
        wrapAround: false,
        cellAlign: 'left',
        freeScroll: true,
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        initialIndex: 0,
      };
    },
  },
  mounted() {
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    tabLength: {
      type: Array,
      required: true,
      default: () => [0, 0, 0, 0],
    },
  },
  watch: {
  },
  methods: {
    tabClicked(tabName) {
      this.selectingCategory = tabName;
      this.$emit('input', tabName);
    }
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.order-price-check-tabs {
  .category-tab {
    padding: .25rem 1rem;
    &.selected {
      font-weight: $font-weight-semi-bold;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $typography-interactive-primary;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.order-price-check-tabs {
}
</style>
