<template>
  <div class="thumbnail-slider" @click.stop>
    <b-carousel
        v-model="activeIndex"
        ref="thumbnailSlider"
        :interval="0"
    >
      <b-carousel-slide v-for="(src, index) in imgSrcArray" :key="index">
        <template #img>
          <div class="carousel-image-wrapper">
            <img class="carousel-image" :src="src" :alt="imgAlt">
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="btn prev" @click.stop="prev">
      <Icon name="slider-left" size="32" />
    </div>
    <div class="btn next" @click.stop="next">
      <Icon name="slider-right" size="32" />
    </div>
    <div class="slider-dots">
      <div class="dot" :class="{ 'active': activeIndex === index }" v-for="(src, index) in imgSrcArray" :key="index" @click="indicatorClick(index)"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThumbnailSlider',
  data() {
    return {
      activeIndex: 0,
    };
  },
  props: {
    imgSrcArray: {
      type: Array,
    },
    imgAlt: {
      type: String,
      default: '',
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    prev() {
      this.$refs.thumbnailSlider.prev();
    },
    next() {
      this.$refs.thumbnailSlider.next();
    },
    indicatorClick(index) {
      this.activeIndex = index;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.thumbnail-slider {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  background: $color-slider-background;
  border-radius: 12px;
  padding: 40px;
  .carousel.slide {
    width: 100%;
    aspect-ratio: 1 / 1;
  }
  .btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
    svg {
      margin: 0;
    }
  }
  .slider-dots {
    position: absolute;
    bottom: 19px;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
    .dot {
      cursor: pointer;
      background: #D9D9D9;
      width: 5.67px;
      height: 5.67px;
      border-radius: 100%;
      &:not(:first-child) {
        margin-left: 6px;
      }
      &.active {
        filter: brightness(60%);
      }
    }
  }
  .carousel-image-wrapper {
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
  }
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
