<template>
  <div class="custom-alert-modal custom-z-index">
    <transition name="fade">
      <div v-if="value" class="backdrop">
        <div class="modal-content">
          <div class="message" v-text="msg"></div>
          <b-button class="mt-4" block variant="primary" @click="confirmClicked">
            <span v-text="btnTxt"></span>
          </b-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CustomAlertModal',
  data() {
    return {
    }
  },
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    msg: {
      type: String,
      required: true,
    },
    btnTxt: {
      type: String,
      required: true,
      default: '確認',
    },
  },
  methods: {
    confirmClicked() {
      console.log('close');
      this.$emit('input', false);
    },
  }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.custom-alert-modal {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--unit-100vh);
    background: $color-bk-tp-60;
  }
  .modal-content {
    width: 375px;
    background: $color-white;
    border: none;
    border-radius: 1.5rem;
    padding: 1rem;
    box-shadow: 0 2px 8px 0 $color-bk-tp-20;
    @include smaller-than-small {
      width: calc(100% - 3rem);
    }
  }
}
</style>
