<template>
  <div class="order-sell-cloth-info">
    <div class="container py-3">
      <div v-if="orderStatus === 3 || orderStatus === 6">
        <div class="desc fs-14px mb-2">
          店家會於期限內回覆您。<br>
          感謝您耐心等候。
        </div>
      </div>
      <div v-if="orderStatus === 1 || orderStatus === 4 || orderStatus === 5">
        <div class="desc fs-14px mb-2">
          敬請於期限內回覆店家。<br>
          感謝您。
        </div>
      </div>
      <div v-if="eventObj.type === 0 && orderStatus === 7">
        <div class="desc fs-14px mb-2">
          <span>衣物上架後可能會依據販售時間或配合銷售活動優惠調整定價，本次寄售分潤比例為 </span>
          <span class="fw-bold" v-text="formattedReturnRatePercentage"></span>
          <span>，實際回饋金將以最終售出定價計算。</span>
        </div>
      </div>
      <div v-if="eventObj.type === 1 && orderStatus === 7">
        <div class="desc fs-14px mb-2">
          捐贈已完成，感謝您！
        </div>
      </div>
      <div v-if="orderStatus === 98">
        <div class="desc fs-14px mb-2">
          無法寄售可能是因為您不滿意店家提供的報價或您所提供的衣物未能符合店家的寄售標準。<br>
          歡迎重新挑選合適的衣物或聯繫店家了解更多細節。<br>
          感謝您對寄售服務的支持及理解！
        </div>
      </div>
      <div v-if="orderStatus !== 7">
        <div class="fs-18px font-weight-bold mb-2">共 {{ list.length }} 件</div>
      </div>
      <div v-if="orderStatus === 7">
        <p>
          衣物上架後可能會依據販售時間或配合銷售活動優惠調整定價，本次寄售分潤比例為 {{ returnPercentageText }}，實際回饋金將以最終售出定價計算。
        </p>

        <h4 class="font-weight-bold">寄售單品：{{ list.length }}件</h4>
      </div>
      <div class="item-list">
        <OrderSellClothItem v-for="(item) in list" :key="item.serial" :sellCloth="item" :eventObj="eventObj" :orderStatus="orderStatus"></OrderSellClothItem>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
// import { mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import OrderSellClothItem from "@/views/share/OrderSellClothItem.vue"
// import OrderPriceCheckItem from "@/views/share/OrderPriceCheckItem.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'OrderSellClothInfo',
  data() {
    return {
      readingBusyName: 'OrderSellClothInfo',
      list: [],
      init: false,
    };
  },
  props: {
    orderSerial: {
      type: Number,
      required: true,
    },
    orderStatus: {
      type: Number,
      required: true,
    },
    eventObj: {
      type: Object,
      require: true,
    },
  },
  components: {
    OrderSellClothItem,
  },
  computed: {
    returnPercentageText() {
      if (this.list.length === 0) {
        return '';
      }
      const p = this.list[0].returnRatePercentage;
      for (const l of this.list) {
        if (l.returnRatePercentage !== p) {
          return 'Mixed';
        }
      }
      return `${p}%`;
    },
    // hasFailedItem() {
    //   return this.list.some(item => item.passStatus === 2);
    // },
    formattedReturnRatePercentage() {
      if(this.eventObj.type === 0) {
        if (this.orderStatus === 7) {
          let firstRate = this.list[0].returnRatePercentage;
          let allSame = this.list.every(
              item => item.returnRatePercentage === firstRate
          );

          return  allSame ? String(firstRate) + '%' : 'Mixed';
        } else {
          const itemsData = this.list.filter(item => item.confirmStatus !== 1 || item.passStatus !== 2) || [];

          if (!this.list || !itemsData || itemsData.length === 0) {
            return '?';
          }

          let firstRate = itemsData[0].returnRatePercentage;
          let allSame = itemsData.every(
              item => item.returnRatePercentage === firstRate
          );

          return  allSame ? String(firstRate) + '%' : 'Mixed';
        }
      } else {
        return 'None Data'
      }
    },
  },
  watch: {
  },
  async mounted() {
    await this.handleAsyncTask(this.readInfo);
  },
  methods: {

    // async readInfo() {
    //   if( this.orderStatus === 7 ) {
    //     const list = await this.$store.dispatch('api/getOrderSellClothListPromise', this.orderSerial);
    //     list.map((d) => {
    //       this.list.push(d);
    //     });
    //   } else {
    //     const data =  await this.$store.dispatch('api/getReviewOrderDetailPromise', this.orderSerial);
    //     data.cloths.map((d) => {
    //       this.list.push(d);
    //     });
    //   }
    // },
    async readInfo() {
      let clothList = [];  // 存儲最终的列表

      if (this.orderStatus === 7) {
        // 獲取第一個列表
        const list = await this.$store.dispatch('api/getOrderSellClothListPromise', this.orderSerial);
        clothList = [...list];

        // 獲取第二個列表
        const reviewData = await this.$store.dispatch('api/getReviewOrderDetailPromise', this.orderSerial);

        // 過濾掉和第一個列表中 `serial` 重複的項目
        const uniqueCloths = reviewData.cloths.filter(reviewCloth =>
          !clothList.some(listCloth => listCloth.sourceClothSerial === reviewCloth.serial)
        );

        // 對 uniqueCloths 進行排序：先 transferStatus === 2，然後 confirmStatus === 2，剩餘的最後
        uniqueCloths.sort((a, b) => {
          if (a.transferStatus === 2 && b.transferStatus !== 2) return -1;
          if (a.transferStatus !== 2 && b.transferStatus === 2) return 1;
          if (a.confirmStatus === 2 && b.confirmStatus !== 2) return -1;
          if (a.confirmStatus !== 2 && b.confirmStatus === 2) return 1;
          return 0;
        });

        // 合併不重複的項目到 clothList
        clothList = [...clothList, ...uniqueCloths];
      } else {
        // 直接獲取第二個列表的數據
        const data = await this.$store.dispatch('api/getReviewOrderDetailPromise', this.orderSerial);
        clothList = [...data.cloths];
      }

      // 將合併後的结果賦值给 this.list
      this.list = clothList;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.order-sell-cloth-info {
  .desc {
    color: $typography-secondary-default;
  }
  .fw-bold {
    color: $typography-primary-default;
  }
  .item-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.order-sell-cloth-info {
  .item-list {
    .order-sell-cloth-item {
      &:not(:first-child) {
        border-top: 1px solid $color-border;
      }
      &:last-child {
        border-bottom: 1px solid $color-border;
      }
    }
  }
}
</style>
  