<template>
  <div class="history-order-status">
    <div class="page-header text-center">
      <h2 class="fs-h2">訂單狀態</h2>
    </div>
    <div class="page-content">
      <div class="order-info">
        <div class="history-image">
          <img :src="statusData.event.displayImg" alt="service image">
        </div>
        <div class="history-info d-flex flex-column justify-content-between">
          <h3 class="name fs-h3" v-text="formattedDisplayName"></h3>
          <div class="desc fs-14px" v-text="statusData.businessAccount.name"></div>
          <div class="price fs-16px fw-bold" v-text="formattedPrice"></div>
        </div>
      </div>
      <div class="order-status">
        <div class="status-progress">
          <div class="progress-bar">
            <div class="percent" :style="statusProgressHeight"></div>
          </div>
        </div>
        <div class="status-group">
          <div class="status-item" v-for="(item, index) in statusInfo[statusData.event.type]" :key="index">
            <div class="status-checked" v-if="index === currentStatusIndex">
              <Icon name="Check_fill" size="21" />
            </div>
            <div class="status-unfinished" v-if="index !== currentStatusIndex"></div>
            <div class="time fs-12px" v-text="item.time"></div>
            <div class="desc fs-12px" :class="{ 'fw-bold': index === currentStatusIndex + 1 }" v-text="item.desc"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-option">
      <b-button variant="primary" block @click="backClicked">
        <span>返回</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'HistoryOrderStatus',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
      statusInfo: [
        [
          { time: '今天', desc: '登記寄售興趣' },
          { time: '額滿截止', desc: '送出衣物資料' },
          { time: '7日內', desc: '店家回覆報價' },
          { time: '7日內', desc: '確認報價' },
          { time: '14日內', desc: '寄出衣物' },
          { time: '7日內', desc: '店家收件' },
          { time: '7日內', desc: '寄售中' },
          { time: '當月月底', desc: '店家匯出回饋金' }
        ],
        [
          { time: '今天', desc: '登記捐贈興趣' },
          { time: '額滿截止', desc: '送出衣物資料' },
          { time: '7日內', desc: '店家回覆收件' },
          { time: '7日內', desc: '確認送件' },
          { time: '14日內', desc: '寄出衣物' },
          { time: '7日內', desc: '店家收件' },
        ],
      ],
    };
  },
  props: {
    statusData: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  computed: {
    formattedDisplayName() {
      switch (this.statusData.event.type) {
        case 0:
          return '[寄售] ' + this.statusData.clothAmount + ' 件' + this.statusData.event.displayName;
        case 1:
          return '[捐贈] ' + this.statusData.clothAmount + ' 件' + this.statusData.event.displayName;
        default:
          return '資料載入錯誤';
      }
    },
    formattedPrice() {
      return '$' + this.statusData.event.reviewPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    currentStatusIndex() {
      switch (this.statusData.status) {
        case 0:
        case 1:
        case 2:
          return 0;
        case 3:
          return 1;
        case 4:
          return 2;
        case 5:
          return 3;
        case 6:
          return 4;
        case 7:
          return 5;
        case 98:
        case 99:
          return 6;
        default:
          return 0;
      }
    },
    statusProgressHeight() {
      const heightPercent = this.statusData.event.type === 0 ? 100/7 : 100/5;
      switch (this.statusData.status) {
        case 0:
        case 1:
        case 2:
          return 'height: ' + heightPercent.toFixed(2) + '%';
        case 3:
          return 'height: ' + ( heightPercent*2 ).toFixed(2) + '%';
        case 4:
          return 'height: ' + ( heightPercent*3 ).toFixed(2) + '%';
        case 5:
          return 'height: ' + ( heightPercent*4 ).toFixed(2) + '%';
        case 6:
          return 'height: ' + ( heightPercent*5 ).toFixed(2) + '%';
        case 7:
          return 'height: ' + ( heightPercent*6 ).toFixed(2) + '%';
        case 98:
        case 99:
          return 'height: ' + ( heightPercent*7 ).toFixed(2) + '%';
        default:
          return 'height: 0';
      }
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    backClicked() {
      this.$emit('back');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.history-order-status {
  .page-header {
    padding: .5rem 0;
    h2.fs-h2 {
      margin-bottom: 0;
    }
  }
  .page-content {
    padding: .5rem;
  }
  .page-option {
    padding: .5rem 0;
  }
  .order-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .history-image {
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid $color-border;
    border-radius: .75rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .history-info {
    h3.fs-h3 {
      margin-bottom: 0;
    }
  }
  .order-status {
    display: flex;
    align-items: stretch;
    padding: .5rem 0;
  }
  .status-progress {
    padding: 9px .5rem;
    .progress-bar {
      position: relative;
      width: 4px;
      height: 100%;
      background: $typography-secondary-default;
      .percent {
        position: absolute;
        top: 0;
        width: 100%;
        background-image: linear-gradient(45deg, $color-main-orange 25%, $color-white 25%, $color-white 50%, $color-main-orange 50%, $color-main-orange 75%, $color-white 75%, $color-white 100%);
        background-size: 8.49px 8.49px;
        animation: progress-bar-stripes 1s linear infinite;
        @keyframes progress-bar-stripes {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: 0 8.49px;
          }
        }
      }
    }
  }
  .status-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .status-item {
    position: relative;
    display: flex;
    gap: 1rem;
    padding-left: .25rem;
    .status-checked {
      position: absolute;
      top: 50%;
      left: -20px;
      border-radius: 100%;
      transform: translateY(-50%);
      &:before {
        position: absolute;
        top: calc(50% + 2px);
        left: 50%;
        z-index: -1;
        display: block;
        content: '';
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 100%;
        transform: translate(-50%, -50%);
      }
    }
    .status-unfinished {
      position: absolute;
      top: 50%;
      left: -16px;
      background: #fff;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 1px solid $typography-primary-default;
      transform: translateY(-50%);
    }
    .time, .desc {
      color: $typography-secondary-default;
    }
    .time {
      width: 60px;
    }
    .desc.fw-bold {
      color: $typography-primary-default;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.history-order-status {
}
</style>
  