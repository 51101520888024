<template>
  <div class="semi-overlay-modal">
    <transition name="fade">
      <div v-if="value" class="backdrop" @click="backClicked"></div>
    </transition>
    <transition name="slide">
      <div v-if="value" class="modal-content">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SemiOverlayModal',
  data() {
    return {

    };
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    backClicked() {
      this.$emit('input', false);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.semi-overlay-modal {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    width: 100%;
    height: var(--unit-100vh);
    background: $color-bk-tp-60;
  }
  .modal-content {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9000;
    width: 100%;
    background: $color-white;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;
  }
}
</style>
<style lang="scss">
.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter, .slide-leave-to {
  transform: translateY(100%);
}
</style>
