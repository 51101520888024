<template>
  <div class="history-order-price-check">
    <div class="price-check-desc fs-14px" v-if="orderData.event.type === 0">
      <span>請於</span>
      <span v-text="formattedDeadlineToDate"></span>
      <span>前確認店家報價。</span>
      <br>
      <span>衣物上架後可能會依據販售時間或配合銷售活動優惠調整定價，本次寄售分潤比例為 </span>
      <span class="fw-bold" v-text="formattedReturnRatePercentage"></span>
      <span>，實際回饋金將以最終售出定價計算。</span>
    </div>
    <div class="price-check-desc fs-14px" v-if="orderData.event.type === 1">
      <span>請於</span>
      <span v-text="formattedDeadlineToDate"></span>
      <span>前回覆店家，確認捐贈衣物件數。</span>
    </div>
    <OrderPriceCheckTabs :tabLength="filteredItemLength" v-model="currentTab" />
    <div class="order-price-check-item-list" v-if="orderDetailData">
      <OrderPriceCheckItem v-for="(item, index) in filteredItemList" :key="index" :itemData="item" :eventType="orderData.event.type" :priceDisplayType="orderData.event.priceDisplayType" :isAllowBargain="isAllowBargain" @updateConfirm="updateConfirm" />
    </div>

    <CustomModalFooter class="form-footer d-flex justify-content-between align-items-center flex-nowrap">
      <div class="info">
        <h3 class="fs-h3">
          <span>已確認 </span>
          <span v-text="confirmedLength"></span>
          <span>/</span>
          <span v-text="totalLength"></span>
          <span> 件</span>
        </h3>
        <div class="desc fs-14px">
          <span>需於 </span>
          <span v-text="formattedDeadline"></span>
          <span> 前送出</span>
        </div>
      </div>
      <b-button variant="primary" :disabled="showConfirmBtn" @click="openConfirmModal">
        <span>{{ orderData.event.type === 0 ? '確認報價' : '確認捐贈' }}</span>
      </b-button>
    </CustomModalFooter>

    <transition name="fade">
      <FullScreenModal v-if="showConfirmPriceCheck" :title="orderData.event.type === 0 ? '確認寄售報價' : '確認捐贈'" btn-type="close" @back="closeConfirmModal">
        <HistoryOrderConfirmPriceCheck :order-data="orderDetailData" :business-data="orderData.businessAccount" @bargain="sendBargain" @confirmPrice="sendConfirmPrice" />
      </FullScreenModal>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import OrderPriceCheckTabs from "@/components/share/OrderPriceCheckTabs.vue";
import OrderPriceCheckItem from "@/views/share/OrderPriceCheckItem.vue";
import CustomModalFooter from "@/components/CustomModalFooter.vue";
import FullScreenModal from "@/components/FullScreenModal.vue";
import HistoryOrderConfirmPriceCheck from "@/views/share/HistoryOrderConfirmPriceCheck.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'HistoryOrderPriceCheck',
  data() {
    return {
      readingBusyName: 'HistoryOrderPriceCheck',

      currentTab: 'pending',
      orderDetailData: null,
      showConfirmPriceCheck: false,

      init: false,
    };
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  components: {
    OrderPriceCheckTabs,
    OrderPriceCheckItem,
    CustomModalFooter,
    FullScreenModal,
    HistoryOrderConfirmPriceCheck,
  },
  computed: {
    showConfirmBtn() {
      if(this.orderDetailData) {
        return this.orderDetailData.cloths.filter(item => item.confirmStatus === 0).length !== 0;
      }
      return true;
    },
    totalLength() {
      return this.orderDetailData?.cloths.length - this.orderDetailData?.cloths.filter(item => item.confirmStatus === 1 && item.passStatus === 2).length || 0;
    },
    confirmedLength() {
      return this.orderDetailData?.cloths.filter(item => item.confirmStatus !== 0 && item.passStatus !== 2).length || 0;
    },
    filteredItemLength() {
      if(this.orderDetailData) {
        let pendingLength = this.orderDetailData.cloths.filter(item => item.confirmStatus === 0).length;
        let confirmedLength = this.orderDetailData.cloths.filter(item => item.confirmStatus === 1 && item.passStatus !== 2).length;
        let rejectedLength = this.orderDetailData.cloths.filter(item => item.confirmStatus === 2).length;
        let failedLength = this.orderDetailData.cloths.filter(item => item.confirmStatus === 1 && item.passStatus === 2).length;
        return [pendingLength, confirmedLength, rejectedLength, failedLength]
      } else {
        return [0, 0, 0, 0];
      }
    },
    filteredItemList() {
      if(this.orderDetailData) {
        switch(this.currentTab) {
          case 'pending':
            return this.orderDetailData.cloths.filter(item => item.confirmStatus === 0);
          case 'confirmed':
            return this.orderDetailData.cloths.filter(item => item.confirmStatus === 1 && item.passStatus !== 2);
          case 'rejected':
            return this.orderDetailData.cloths.filter(item => item.confirmStatus === 2);
          case 'failed':
            return this.orderDetailData.cloths.filter(item => item.confirmStatus === 1 && item.passStatus === 2);
          default:
            return [];
        }
      } else {
        return [];
      }
    },
    formattedReturnRatePercentage() {
      const itemsData = this.orderDetailData?.cloths.filter(item => item.confirmStatus !== 1 || item.passStatus !== 2) || [];

      if (!this.orderDetailData || !itemsData || itemsData.length === 0) {
        return 'No data';
      }

      let firstRate = itemsData[0].returnRatePercentage;
      let allSame = itemsData.every(
          item => item.returnRatePercentage === firstRate
      );

      return  allSame ? String(firstRate) + '%' : 'Mixed';
    },
    formattedDeadline() {
      const getFormattedDate = (date) => {
        return moment(date).add(7, 'days').endOf('day').format('YYYY/MM/DD HH:mm');
      };

      return getFormattedDate(this.orderData.waitQuoteDate);
    },
    formattedDeadlineToDate() {
      return moment(this.formattedDeadline).format('MM/DD');
    },
    isAllowBargain() {
      return this.orderData.event.communicateLimit > this.orderData.bargainCount
    }
  },
  watch: {
  },
  async mounted() {
    await this.handleAsyncTask(this.readInfo);
  },
  methods: {
    ...mapActions('event', ['refreshHistoryList', 'clearHistoryListData']),

    async readInfo() {
      this.orderDetailData = await this.$store.dispatch('api/getReviewOrderDetailPromise', this.orderData.serial);
    },
    async updateConfirm() {
      console.log('updateConfirm')
      await this.handleAsyncTask(this.readInfo);
    },
    openConfirmModal() {
      this.showConfirmPriceCheck = true;
    },
    closeConfirmModal() {
      this.showConfirmPriceCheck = false;
    },
    async sendBargain() {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postReviewOrderSendBargainPromise', parseInt(this.orderData.serial));
      });
      await this.refreshHistoryList();
      this.$emit('close');
    },
    async sendConfirmPrice(id) {
      await this.handleAsyncTask(async () => {
        await this.$store.dispatch('api/postReviewOrderSendConfirmPromise', { serial: parseInt(this.orderData.serial), agreeId: id });
      });
      await this.refreshHistoryList();
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.history-order-price-check {
  padding-bottom: 5rem;
  .price-check-desc {
    color: $typography-secondary-default;
    padding: 1rem;
    .fw-bold {
      color: $typography-primary-default;
    }
  }
  .order-price-check-item-list {
    padding-top: 1rem;
  }
  .info {
    .fs-h3 {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.history-order-price-check {
  .order-price-check-item-list {
    .order-price-check-item {
      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }
    }
  }
}
</style>
  