<template>
  <div class="order-price-check-item">
    <div class="price-check-content">
      <div class="item-image">
        <ThumbnailSlider :img-src-array="itemData.imgs" :img-alt="'product-image'" />
      </div>
      <div class="item-content" v-if="itemStatus !== 'failed' && itemStatus !== 'error'">
        <div class="item-info" v-if="hideEditBtn">
          <div class="brand-name fs-12px fw-bold" :class="{'unfilled': itemData.brand === ''}" v-text="formattedBrandName"></div>
          <div class="item-name fs-16px" :class="{'unfilled': !Boolean(category1 && category2)}" v-text="formattedCardName"></div>
        </div>
        <div class="price-info" v-if="eventType === 0">
          <div class="fs-16px d-flex justify-content-between">
            <div>購入價</div>
            <div v-text="formattedBoughtPrice"></div>
          </div>
          <div class="fs-16px d-flex justify-content-between">
            <div>{{ priceDisplayType === 0 ? '預期回饋金' : '預計售價' }}</div>
            <div v-text="priceDisplayType === 0 ? formattedRebate : formattedPredictPrice"></div>
          </div>
          <div class="warning-text fs-16px d-flex justify-content-between" v-if="itemData.needBargain">
            <div>議價金額</div>
            <div v-text="formattedBargainPrice"></div>
          </div>
        </div>
        <div class="price-option" v-if="itemStatus !== 'failed' && !hideEditBtn">
          <b-button class="option-btn fs-14px fw-bold d-flex justify-content-center align-items-center" variant="outline-primary" :disabled="itemData.passStatusLock || itemStatus === 'confirmed'" @click="toggleStatus('confirmed')">
            <Icon name="check" size="16" />
            <span>同意</span>
          </b-button>
          <b-button class="option-btn fs-14px fw-bold d-flex justify-content-center align-items-center" variant="outline-primary" :disabled="itemData.passStatusLock" v-if="itemStatus !== 'rejected'" @click="toggleStatus('rejected')">
            <Icon name="close" size="16" />
            <span>不同意</span>
          </b-button>
          <b-button class="option-btn fs-14px fw-bold d-flex justify-content-center align-items-center" variant="outline-primary" :disabled="itemData.passStatusLock || !isAllowBargain" v-if="itemStatus === 'rejected' && eventType === 0" @click="openBargainSettings">
            <Icon name="switch" size="16" />
            <span>{{ itemData.bargainPrice === 0 ? '議價' : '編輯議價' }}</span>
          </b-button>
          <!--        <b-button class="option-btn fs-14px fw-bold" variant="outline-primary" v-if="itemStatus === 'rejected'">-->
          <!--          <Icon name="switch" size="16" />-->
          <!--          <span>編輯議價</span>-->
          <!--        </b-button>-->
        </div>
      </div>
      <div class="item-content" v-if="itemStatus === 'failed'">
        <div class="item-info">
          <div>
            <div class="brand-name fs-12px fw-bold" :class="{'unfilled': itemData.brand === ''}" v-text="formattedBrandName"></div>
            <div class="item-name fs-14px" :class="{'unfilled': !Boolean(category1 && category2)}" v-text="formattedCardName"></div>
          </div>
          <div class="fs-16px" v-text="itemData.failedReason"></div>
        </div>
      </div>
    </div>

    <SemiOverlayModal v-model="showBargainSettings">
      <div class="bargain-settings">
        <h2 class="fs-h2 text-center">預期{{ priceDisplayType === 0 ? '回饋金' : '售價' }}</h2>
        <div class="desc">
          提高{{ priceDisplayType === 0 ? '回饋金' : '售價' }}將降低出售的機率或拉長售出時間，廠商亦保留最終接受議價的權利。
        </div>
        <div class="bargain-input-group">
          <div class="bargain-input">
            <span>$</span>
            <input type="number" inputmode="numeric" min="0" class="fs-16px" v-model="bargainPrice">
          </div>
          <div class="desc fs-14px">
            <!-- 這邊之後可能要改  目前MVP測試 2024/08/28 -->
            每件單品僅可議價一次。
          </div>
        </div>
        <b-button class="bargain-btn fs-16px" variant="primary" @click="updateBargain">
          <span>確認</span>
        </b-button>
        <b-button class="bargain-btn warning fs-16px" variant="outline-primary" @click="closeBargainSettings">
          <span>取消議價</span>
        </b-button>
      </div>
    </SemiOverlayModal>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import ThumbnailSlider from "@/components/ThumbnailSlider.vue";
import SemiOverlayModal from "@/components/SemiOverlayModal.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'OrderPriceCheckItem',
  data() {
    return {
      readingBusyName: 'OrderPriceCheckItem',

      showBargainSettings: false,
      bargainPrice: 0,
    };
  },
  props: {
    itemData: {
      type: Object,
      require: true,
    },
    eventType: {
      type: Number,
      require: true,
    },
    priceDisplayType: {
      type: Number,
      require: true,
    },
    hideEditBtn: {
      type: Boolean,
      default: false,
    },
    hideRemoveBtn: {
      type: Boolean,
      default: false,
    },
    isAllowBargain: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ThumbnailSlider,
    SemiOverlayModal,
  },
  computed: {
    ...mapState(['clothCategories']),

    category1() {
      const res = this.clothCategories.filter((item) => {
        return item.serial === this.itemData.category1;
      });
      if (res.length > 0) {
        return res[0];
      }
      return null;
    },
    category2() {
      for (const c1 of this.clothCategories) {
        for (const c2 of c1.sub) {
          if (c2.serial === this.itemData.category2) {
            return c2;
          }
        }
      }
      return null;
    },
    formattedCardName() {
      return this.category1 && this.category2 ? this.category1.name + ' / ' + this.category2.name : '單品類別'
    },
    formattedBoughtPrice() {
      return '$' + this.itemData.boughtPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formattedPredictPrice() {
      if(this.itemData.predictPriceMin === 0 && this.itemData.predictPriceMax === 0) {
        return '$0';
      } else {
        return '$' + this.itemData.predictPriceMin + '-' + this.itemData.predictPriceMax;
      }
    },
    formattedRebateMin() {
      return this.calculateProfit(this.itemData.predictPriceMin);
    },
    formattedRebateMax() {
      return this.calculateProfit(this.itemData.predictPriceMax);
    },
    formattedRebate() {
      if(this.itemData.predictPriceMin === 0 && this.itemData.predictPriceMax === 0) {
        return '$0';
      } else {
        return '$' + this.formattedRebateMin + '-' + this.formattedRebateMax;
      }
    },
    formattedBargainPrice() {
      return '$' + this.itemData.bargainPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formattedBrandName() {
      return this.itemData.brand ? this.itemData.brand : '品牌名';
    },
    // priceAndSizeUnfilled() {
    //   return this.cardData.price === 0 || this.cardData.price === null || this.cardData.size === null || this.cardData.size === '';
    // },
    // condition() {
    //   return this.cardData.erosionRate ? this.cardData.erosionRate : '新舊程度'
    // },
    itemStatus() {
      const { confirmStatus, passStatus } = this.itemData;

      if (confirmStatus === 0) {
        return 'pending';
      }

      if (confirmStatus === 1) {
        if (passStatus === 2) {
          return 'failed';
        }
        return 'confirmed';
      }

      if (confirmStatus === 2) {
        return 'rejected';
      }

      return 'error';
    }
  },
  watch: {
    bargainPrice(value) {
      if (value < 0) {
        this.bargainPrice = 0;
      } else {
        this.bargainPrice = Number(value);
      }
    }
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),

    calculateProfit(amount) {
      if (this.itemData.returnRatePercentage > 0) {
        const profit = (amount * this.itemData.returnRatePercentage) / 100;
        return Math.ceil( profit );
        // return Math.floor( profit );
      } else {
        return 0;
      }
    },
    async toggleStatus(status) {
      console.log(status)
      await this.handleAsyncTask(async () => {
        let data = {
          serial: this.itemData.serial,
          confirmStatus: 0,
          needBargain: false,
          bargainPrice: 0,
        }

        switch(status) {
          case 'confirmed':
            console.log('confirmed');
            data.confirmStatus = 1;
            data.needBargain = false;
            data.bargainPrice = 0;
            await this.updateConfirm(data);
            this.$emit('updateConfirm')
            break;
          case 'rejected':
            console.log('rejected');

            data.confirmStatus = 2;
            data.needBargain = false;
            data.bargainPrice = 0;
            await this.updateConfirm(data);
            this.$emit('updateConfirm')
            break;
          default:
            console.log('toggleStatus error!');
        }
      });
    },
    async updateConfirm(data) {
      await this.$store.dispatch('api/postReviewClothUpdateConfirmPromise', data);
    },
    openBargainSettings() {
      this.showBargainSettings = true;
    },
    closeBargainSettings() {
      this.showBargainSettings = false;
    },
    async updateBargain() {
      await this.handleAsyncTask(async () => {
        let data = {
          serial: this.itemData.serial,
          confirmStatus: 2,
          needBargain: true,
          bargainPrice: this.bargainPrice,
        }
        await this.updateConfirm(data);
        this.closeBargainSettings();
        this.$emit('updateConfirm');
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.order-price-check-item {
  padding: 1rem;
  .price-check-content {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
  }
  .item-image {
    flex-shrink: 0;
    width: 120px;
    height: 120px;
  }
  .item-content, .item-info, .price-info {
    display: flex;
    flex-direction: column;
  }
  .item-content {
    flex-grow: 1;
    gap: 1rem;
  }
  .item-info {
    gap: .25rem;
  }
  .price-info {
    gap: .125rem;
    &.justify-content-between {
      height: 120px;
    }
    .warning-text {
      color: $typography-primary-error;
    }
  }
  .price-option {
    display: flex;
    gap: 1rem;
    .option-btn {
      width: calc((100% - 1rem)/2);
      padding: 0.5rem;
      svg {
        margin-right: .5rem;
      }
    }
  }
  .brand-name {
    color: $typography-primary-default;
    &.unfilled {
      color: $typography-secondary-default;
    }
  }
  .item-name, .item-info {
    color: $typography-primary-default;
    margin-bottom: .125rem;
    &.unfilled {
      color: $typography-secondary-default;
    }
  }
  .item-info {
    .divide {
      background: $color-border;
      width: 1px;
      height: .5rem;
      margin: 0 .5rem;
    }
  }
  .desc {
    color: $typography-secondary-default;
  }
  .fill-info-btn {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
  }
  .bargain-settings {
    .fs-h2 {
      margin-bottom: .75rem;
    }
    .desc {
      margin-bottom: .75rem;
    }
    .bargain-input-group {
      margin-bottom: .75rem;
      .bargain-input {
        background: $color-image-background;
        width: 100%;
        border-radius: .5rem;
        padding: .5rem 1rem;
        margin-bottom: .25rem;

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
          background: $color-image-background;
          border: none;
          padding: 0;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .desc {
        margin-bottom: 0;
      }
    }
    .bargain-btn {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: .75rem;
      }
      &.warning {
        color: $typography-primary-error;
        border: 1px solid $typography-primary-error;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.order-price-check-item {
  .item-image {
    .thumbnail-slider {
      background: $color-btn-disabled-background-primary;
      padding: 1.25rem;
      .slider-dots {
        bottom: .5rem;
        .dot {
          width: 4px;
          height: 4px;
          &:not(:first-child) {
            margin-left: 4.5px;
          }
        }
      }
    }
  }
}
</style>
  